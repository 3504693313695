import Vue from 'vue'
import App from './App.vue'
import router from './router'
import JsonExcel from 'vue-json-excel'
import { Icon } from '@iconify/vue2';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import VueMeta from 'vue-meta'
import VueQrcodeReader from "vue-qrcode-reader";
import VueAnimateOnScroll from 'vue-animate-onscroll'
import VueSignaturePad from 'vue-signature-pad';
import vueAwesomeCountdown from 'vue-awesome-countdown'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import VueSmoothScroll from 'vue2-smooth-scroll'
//import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueScreen from 'vue-screen';
import VueMarqueeSlider from 'vue-marquee-slider';
import VueSocialSharing from 'vue-social-sharing'
import VModal from 'vue-js-modal'

import './assets/main.css?v.1'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
  confirmButtonColor: '#0B3C7A',
  cancelButtonColor: '#FF678B',
};

Vue.use(VueSweetalert2, options);

library.add(fas, far, fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component("v-excel", JsonExcel)
Vue.component("iconify-icon", Icon)
Vue.config.productionTip = false
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(VueQrcodeReader);
Vue.use(VueAnimateOnScroll);
Vue.use(VueSignaturePad);
Vue.use(vueAwesomeCountdown, 'vac')
Vue.use(VueVideoPlayer)
Vue.use(VueSmoothScroll)
Vue.use(VueScreen, 'tailwind');
Vue.use(VueMarqueeSlider)
Vue.use(VueSocialSharing);
Vue.use(VModal)
//Vue.use(VueReCaptcha, { siteKey: '6Lc3QzMlAAAAAP7x42LVN-PO6IkyXkxg8tRYxcaN', oaderOptions: { autoHideBadge: true, explicitRenderParameters: { badge: 'bottomleft'} } })

new Vue({
  router,
  render: h => h(App),
  mounted: () => {
    document.dispatchEvent(new Event("x-app-rendered"));
  },
}).$mount('#app')
