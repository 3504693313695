<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | ShikiFest'
  },
}
</script>